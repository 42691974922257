import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Jonathan Clinton',
  subtitle: 'Welcome to my UX portfolio!',
  cta: 'About Me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I'm a spring 2020 graduate who studied Informatics with a specialization in Human-Computer Interaction at the University of California, Irvine. I learned many valuable skills though courses such as project management, programming, requirements analysis, software engineering, UI/UX design, and UX research.",
  paragraphTwo:
    'Empathy was a vital part in all my previous roles and enabled me to understand not only the users’ immediate frustrations but also hopes, fears, abilities, limitations, reasoning, and goals. I truly enjoyed figuring out how I can best use technology to simplify their lives.',
  paragraphThree:
    'I am pursuing UX research opportunities as it is an interdisciplinary field that I believe can fully utilize my burning passion for user experience, deep intellectual curiosity in user behavior, and love for beautiful design.',
  resume: 'https://drive.google.com/file/d/1PnoiXv5tGINib4aNJoEpwWHCq0qBPXBj/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.png',
    title: "Fry's Electronics - UX Research Case Study",
    info:
      'Sponsored upper-division team project focused on HCI requirements and evaluation by means of user interface redesign',
    info2: 'Skills: UX Research, UI/UX Design,',
    info3: 'Tools: draw.io, Figma, G Suite',
    url: 'https://jclintonme.myportfolio.com/',
    repo: '', // if no repo, the button will not show up
    fullstudy:
      'https://docs.google.com/document/d/1wmM1Y6H0w8ZO75hItWgD5CCmESTwoc7OQqn8eSWKQrk/edit',
  },
  {
    id: nanoid(),
    img: 'project5.png',
    title: 'IntelleXt - UX Research Deliverables',
    info:
      'Developed user interview protocol to gather insights from participants with contract negotiation experience. Affinity mapped interview data to build persona and design journey map.',
    info2: 'Skills: UX Research',
    info3: 'Tools: Miro, G Suite',
    url: 'https://jclinton1.myportfolio.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project6.png',
    title: 'Hack for LA - BallotNav (Ballot Drop Box Locator)',
    info: 'Designed 5-second tests and preference tests for two homepage design mockups',
    info2: 'Skills: UX Research',
    info3: 'Tools: UsabilityHub',
    url: 'https://jclinton2.myportfolio.com',
    repo: 'https://github.com/hackforla/ballotnav/projects/7', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project4.png',
    title: 'Code for San Jose - City Council Meeting App',
    info:
      'RITE testing on web and mobile apps to make public meetings in the City of San José more accessible for community members.',
    info2: 'Skills: UX Research',
    url:
      'https://docs.google.com/document/d/1KYic2gBN8QYDtSXzGbPauRKVxTBVXF0QIaTKBjquGo0/edit?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.png',
    title: 'Oregon Health & Science University - UX Research Challenge',
    info:
      'UX research challenge in helping patients confidently and quickly choose a healthcare service for the need they have',
    info2: 'Skills: UX Research',
    url: 'https://drive.google.com/file/d/1Kaq1wj8PmHvY5tt7H7JfLu3LWlGHz1MU/view?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project0.png',
    title: 'Sparkset - CRM Web App',
    info:
      'Capstone team project focused on developing a CRM web application for Sparkset, a customer experience and digital marketing agency',
    info2: 'Skills: Project Management, UX Design, UX Research, Front-End Development',
    info3: 'Tools: CSS, HTML, Vue.js, LeanCloud database, G Suite, Github, Slack, Trello',
    url:
      'https://docs.google.com/document/d/1vA_mzOVe7c5M7ucIkCfFpVjvV-jDcb6kvlrZ59EdGg4/edit?usp=sharing',
    repo: 'https://github.com/shifuchen98/sparkset', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.png',
    title: 'Gaming Community App Prototype (1-Day Design Challenge)',
    info:
      'Platform designed to help adult, first-time gamers with busy schedules to find appropriate games, explore game content, and interact with the gaming community',
    info2: 'Skills: UX Design',
    info3: 'Tools: Adobe XD',
    url: 'https://xd.adobe.com/view/0a35c361-5dbd-4ae8-9ff6-38a8e605bd41-5f66/?fullscreen',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Any questions?',
  btn: 'Email me!',
  email: 'jclinton9873@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/clintonjon/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
